// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiUrl: 'https://signupapi.thinkenergy.plus/',
  productUrl: 'https://signupapi.thinkenergy.plus/',
  imageUrl: 'https://energywell.corpadmin.directscale.com/CMS/Images/Inventory',
  clientExtensionAPI: 'https://energywell.clientextension.directscale.com/'
};
  // these are apis for live below
  // apiUrl: 'https://signupapi.thinkenergy.plus/',
  // productUrl: 'https://signupapi.thinkenergy.plus/',
  // imageUrl: 'https://energywell.corpadmin.directscale.com/CMS/Images/Inventory'

  //these are stage apis
  // apiUrl: 'https://energywellapi.ziplingo.com/',
  // productUrl: 'https://energywellapi.ziplingo.com/',
  // imageUrl: 'https://energywell.corpadmin.directscale.com/CMS/Images/Inventory'
